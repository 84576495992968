import tw, { styled } from "twin.macro"

import ConditionalWrapper from "@components/ConditionalWrapper"
import { Case, Default, Else, If, Switch, Then } from "@components/If"
import Skeleton from "@components/Skeleton"
import Icons from "@components/v3/Icons"
import { formatChangePercentage } from "@utils/formatPercentage"

const Wrapper = tw.div`
    inline-flex items-center
`

interface BackgroundProps {
    variant: "success" | "danger" | "neutral"
}

const Background = styled.div<BackgroundProps>`
    ${tw`py-[3px] px-2 flex items-center`}
    ${tw`rounded-full`}
    ${({ variant }) => {
        switch (variant) {
            case "success":
                return tw`bg-success-25 dark:bg-success-500/20`
            case "danger":
                return tw`bg-danger-25 dark:bg-danger-500/20`
            default:
                return tw`bg-neutral-25 dark:bg-neutral-500/20`
        }
    }}
`

const VariantText = styled.div<BackgroundProps>`
    ${tw`title-5`}
    ${({ variant }) => {
        switch (variant) {
            case "success":
                return tw`text-success dark:text-dark-success`
            case "danger":
                return tw`text-error dark:text-dark-error`
            default:
                return tw`text-additional dark:text-dark-additional`
        }
    }}
`

interface Props {
    /**
     * Set percentage value number
     */
    value?: number
    /**
     * Set className
     */
    className?: string
    /**
     * Prefix type
     */
    prefix?: "icon" | "symbol" | "none"
    /**
     * with background
     */
    withBackground?: boolean
    /**
     * icon size
     */
    iconSize?: number

    /**
     * precision
     */
    precision?: number | null
}

const ChangePercentageText: React.FC<Props> = ({
    value,
    className,
    prefix,
    withBackground,
    iconSize,
    precision
}: Props) => {
    const isPositive = typeof value !== "undefined" && value > 0
    const isNegative = typeof value !== "undefined" && value < 0

    const getVariant = () => {
        if (isPositive) {
            return "success"
        }

        if (isNegative) {
            return "danger"
        }

        return "neutral"
    }

    return (
        <Wrapper className='reku-new'>
            <ConditionalWrapper
                condition={withBackground ?? false}
                // eslint-disable-next-line react/no-unstable-nested-components
                wrapper={(children) => <Background variant={getVariant()}>{children}</Background>}
            >
                <>
                    <If condition={prefix === "icon"}>
                        <Switch>
                            <Case condition={isPositive}>
                                <Icons
                                    icon='ArrowSlantUp'
                                    className='text-icon-success dark:text-dark-success mr-1'
                                    width={iconSize}
                                    height={iconSize}
                                />
                            </Case>
                            <Case condition={isNegative}>
                                <Icons
                                    icon='ArrowSlantDown'
                                    className='text-icon-error dark:text-dark-error mr-1'
                                    width={iconSize}
                                    height={iconSize}
                                />
                            </Case>
                            <Default />
                        </Switch>
                    </If>
                    <If condition={typeof value === "undefined"}>
                        <Then>
                            <VariantText variant='neutral' className={className}>
                                <Skeleton width={60} />
                            </VariantText>
                        </Then>
                        <Else>
                            {typeof value !== "undefined" && (
                                <VariantText variant={getVariant()} className={className}>
                                    {formatChangePercentage(value, prefix === "symbol" ? prefix : "none", precision)}
                                </VariantText>
                            )}
                        </Else>
                    </If>
                </>
            </ConditionalWrapper>
        </Wrapper>
    )
}

ChangePercentageText.defaultProps = {
    value: undefined,
    className: undefined,
    prefix: "symbol",
    withBackground: false,
    iconSize: 14,
    precision: undefined
}

export default ChangePercentageText
